import './App.css';
import Footer from './Components/Footer';
import Navbar from './Components/Navbar';
import Home from './Views/Home';
import {Fade} from 'react-awesome-reveal';
function App() {
  return (
    <Fade>
      <main className=' font-Lora bg-black'>
        <Navbar />
        <Home />
        <Footer />
      </main>
    </Fade>
  );
}

export default App;
