import React from "react";
import { faCertificate} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../Components/Button";
import telBack from "../img/img1.png";
import jbl from "../img/jbl.png";
import SwiperSer from "../Components/SwiperSer";
import {Fade , Slide , JackInTheBox} from 'react-awesome-reveal';


export default function Home(){
    return (
        <section className="relative overflow-x-hidden">
            <div className="w-screen bg-pagel bg-no-repeat bg-center bg-cover h-screen">
                <div className=" flex justify-center items-center h-screen">
                    <div className=" flex flex-col justify-center space-y-8 lg:space-y-12  lg:p-0 p-5 text-center">
                        <div className=" bg-black/50 cursor-pointer hover:opacity-50 shadow-md lg:shadow-lg rounded-full flex px-6 py-2 w-full space-x-4 lg:space-x-6 justify-center place-items-center text-center lg:w-[800px] lg:py-7">
                            <FontAwesomeIcon className=" lg:text-4xl hidden lg:flex text-main-color" icon={faCertificate} />
                            <span className=" tracking-wide font-bold text-white text-3xl lg:text-5xl ">Hernandez Envios</span>
                        </div>
                        <div className="p-2">
                            <div className="italic">
                                <ul className=" text-main-color space-y-2 font-light capitalize">
                                    <li>
                                        <h2 className="text-2xl md:text-4xl tracking-wide">
                                            Dinero a cualquier
                                        </h2>
                                    </li>
                                    <li>
                                        <h2 className="text-2xl md:text-4xl tracking-wide">
                                            lugar, rápido
                                        </h2>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className=" bg-black pt-8 pb-8">
                <Fade delay={100}>
                    <div className="lg:p-8 p-4 mx-auto space-y-6 lg:space-y-12 max-w-screen-xl">
                        <div className=" flex flex-col text-center justify-center space-y-5 lg:space-y-12">
                            <div className="">
                                <span className=" tracking-normal text-main-color leading-none font-bold text-4xl lg:text-6xl capitalize">servicios de dinero</span>
                            </div>
                            <div className=" flex text-white justify-center">
                                <div className="">
                                    <span className=" tracking-tight text-xl lg:text-4xl">
                                        Podemos enviar a diferentes empresas en México, hasta 25 compañías distintas como Elektra, Bancoppel y más
                                    </span>
                                </div>
                            </div>
                            <div>
                                <Button name = "Mande con nosotros" />
                            </div>
                        </div>
                    </div>
                </Fade>
            </div>
            <div className=" bg-[#212121] rounded-3xl lg:py-12 lg:px-24 pt-8 lg:pb-8 pb-0">
                <Fade delay={200}>
                    <div className="text-white space-y-6 lg:space-y-12">
                        <div className=" grid lg:grid-cols-2 lg:gap-8">
                            <div className=" flex flex-col py-6 lg:py-16 space-y-8 lg:space-y-8">
                                <div className=" text-center">
                                    <h1 className="tracking-normal leading-none font-bold text-4xl lg:text-6xl text-main-color capitalize">Telefonos liberados</h1>
                                </div>
                                <div className=" text-center lg:px-0 px-8">
                                    <span className=" text-xl lg:text-2xl">
                                        Descubre nuestra exclusiva colección de teléfonos de última generación, incluyendo iPhones, 
                                        Samsungs y Motorolas, y enriquece tu experiencia tecnológica con nuestra amplia gama de 
                                        accesorios únicos para todo tipo de dispositivos.
                                    </span>
                                </div>
                                <div className=" flex justify-center">
                                    <Button name = "Seleccion de Telefonos" />
                                </div>
                            </div>
                            <div className=" flex cursor-pointer">
                                <div className="shadow-lg overflow-hidden rounded-lg">
                                    <img className="w-full rounded-3xl ease-in-out transition-all duration-1000 hover:scale-125" src={telBack}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </div>
            <div className=" bg-black pt-8 pb-4">
                <Slide>
                    <div className="lg:p-8 p-4 mx-auto space-y-6 lg:space-y-12 max-w-screen-xl">
                        <div className="flex flex-col text-center items-center space-y-8">
                            <span className = "tracking-normal leading-none font-bold text-4xl lg:text-6xl text-main-color capitalize">Boost Mobile Cricket & AT&T</span>
                            <div className=" text-center lg:px-0 px-2 text-white">
                                <span className=" text-xl lg:text-2xl">
                                    ¡Ven y descubre la comodidad de activar y adquirir teléfonos con nosotros, 
                                    ofreciendo opciones de operadoras líderes como Cricket, Boost Mobile y AT&T, 
                                    todo en un solo lugar para tu conveniencia!
                                </span>
                            </div>
                        </div>
                        <div className="">
                            <SwiperSer />
                        </div>
                        <div className=" flex justify-center">
                            <Button name = "Seleccion de Servicios" />
                        </div>
                    </div>
                </Slide>
            </div>
            <div className=" bg-[#212121] rounded-3xl lg:py-12 lg:px-24 lg:pb-8 pb-0">
                <div className="text-white space-y-6 lg:space-y-12">
                    <div className=" grid lg:grid-cols-2 lg:gap-8">
                        <div className=" flex cursor-pointer">
                            <JackInTheBox delay={200}>
                                <div className="shadow-lg overflow-hidden rounded-lg">
                                    <img className="w-full rounded-3xl ease-in-out transition-all duration-1000 hover:scale-125" src={jbl}/>
                                </div>
                            </JackInTheBox>
                        </div>
                        <div className=" flex flex-col py-6 lg:py-16 space-y-8 lg:space-y-8">
                            <Fade delay={200}>
                                <div className=" text-center">
                                    <h1 className="tracking-normal leading-none font-bold text-4xl lg:text-6xl text-main-color capitalize">JBL</h1>
                                </div>
                                <div className=" text-center lg:px-0 px-8">
                                    <span className=" text-xl lg:text-2xl">
                                        Explora lo último en sonido con nuestra gama de altavoces y auriculares JBL, y más, 
                                        ¡perfectos para elevar tu experiencia auditiva a un nivel superior
                                    </span>
                                </div>
                                <div className=" flex justify-center">
                                    <Button name = "Seleccion de Telefonos" />
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
            <div className=" bg-black rounded-3xl lg:py-12 lg:px-24 pt-8 lg:pb-8 pb-0">
                <div className="text-white space-y-6 lg:space-y-12">
                    <div className=" grid lg:grid-cols-2 lg:gap-8">
                        <div className=" flex flex-col py-6 lg:py-16 space-y-8 lg:space-y-8">
                            <div className=" text-center">
                                <h1 className="tracking-normal leading-none font-bold text-4xl lg:text-6xl text-main-color capitalize">Donde Estamos?</h1>
                            </div>
                            <div className=" text-center lg:px-0 px-8">
                                <span className=" text-xl lg:text-2xl">
                                    Bienvenidos a Salinas Cellular! Ubicados en 
                                    495 E Market St, Salinas, CA 93906, 
                                    somos tu destino ideal para todas tus necesidades de telefonía móvil.
                                </span>
                            </div>
                            <div onClick={(e) => {e.preventDefault(); window.open("https://maps.app.goo.gl/d9VpCzRobB8ga1Gk6" , '_blank')}} className=" flex justify-center">
                                <Button name = "Ubicación" />
                            </div>
                        </div>
                        <div className=" flex w-full lg:p-0 p-3 h-full cursor-pointer">
                            <iframe className=" w-full h-[400px] lg:h-full " src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3199.8806934114104!2d-121.6382518!3d36.6773695!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808df898478656b5%3A0xb94a36f28dfc7d2!2s495%20E%20Market%20St%2C%20Salinas%2C%20CA%2093905!5e0!3m2!1sen!2sus!4v1710748820353!5m2!1sen!2sus"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};