
const Data = [
    {
        id: 1,
        img: require('../img/1.png')
    },
    {
        id: 2,
        img: require('../img/2.png')
    },
    {
        id: 3,
        img: require('../img/3.png')
    }
];

export default Data;