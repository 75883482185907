import React from "react";
import {Swiper , SwiperSlide} from 'swiper/react';
import 'swiper/css';
import {Autoplay} from "swiper/modules";
import 'swiper/css/effect-coverflow';
import Data from "./Data";

export default function SwiperSer(){
    return (
        <>
            <Swiper className=""
                loop = {true}
                breakpoints={{
                    340: {
                        slidesPerView:1,
                    },
                    700: {
                        slidesPerView: 3,
                        spaceBetween: 15
                    }
                }}
                autoplay = {{
                    delay: 1000,
                    disableOnInteraction: false,
                }}
                
                grabCursor={true}
                modules={[Autoplay]}
            >
                {Data.map((d) => (
                    <SwiperSlide key={d.id} className="">
                        <div className="cursor-pointer rounded-lg duration-200 delay-100 ease-in-out">
                            <div className=" sticky h-[300px] lg:h-[500px]">
                                <img alt="#" className="rounded-lg w-full h-full" src={d.img} />
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    )
};